.modal-wrapper {
    background: white;
    border: 1px solid #d0cccc;
    box-shadow: 0 5px 8px 0 rgba(0,0,0,0.2), 0 7px 20px 0 rgba(0,0,0,0.17);
    top: 20%;
    left: 25%;
    transition: all .7s;
    width: 50%;
    border-radius: 10px;
    position: absolute;
}

.modal-wrapper-sm {
    background: white;
    border: 1px solid #d0cccc;
    box-shadow: 0 5px 8px 0 rgba(0,0,0,0.2), 0 7px 20px 0 rgba(0,0,0,0.17);
    top: 20%;
    left: 30%;
    transition: all .7s;
    width: 40%;
    border-radius: 10px;
    position: absolute;
}

.modal-wrapper-lg {
    background: white;
    border: 1px solid #d0cccc;
    box-shadow: 0 5px 8px 0 rgba(0,0,0,0.2), 0 7px 20px 0 rgba(0,0,0,0.17);
    top: 20%;
    left: 15%;
    transition: all .7s;
    width: 70%;
    border-radius: 10px;
    position: absolute;
}

.modal-wrapper-full {
    background: white;
    border: 1px solid #d0cccc;
    box-shadow: 0 5px 8px 0 rgba(0,0,0,0.2), 0 7px 20px 0 rgba(0,0,0,0.17);
    top: 20%;
    left: 5%;
    transition: all .7s;
    width: 90%;
    border-radius: 10px;
    position: absolute;
}

.modal-header {
    background: #ebebeb;
    height: 50px;
    line-height: 30px;
    padding: 5px 20px;
    text-align: right;
}

.modal-header h3 {
    color: rgb(34, 33, 33);
    float: left;
    margin: 0;
    padding: 5px 0;
}

.modal-body {
    padding: 10px 15px;
}

.modal-footer {
    height: 60px;
    padding: 10px;
}

.close-modal-btn {
    color: rgb(0, 0, 0);
    cursor: pointer;
    float: right;
    font-size: 20px;
    margin: 0;
    background-color: white;
    padding: 0 10px;
    border-radius: 5px;
    top: -5px !important;
    right: -10px !important;
    position: absolute;
    box-shadow: 0 5px 8px 0 rgba(0,0,0,0.2), 0 7px 20px 0 rgba(0,0,0,0.17);
}

.close-modal-btn:hover {
    color: black;
}

.btn-cancel, .btn-continue {
    background: coral;
    border: none;
    color: white;
    cursor: pointer;
    font-weight: bold;
    outline: none;
    padding: 10px;
}

.btn-cancel {
    background-color: #b71c1c;
    float: left;
}

.btn-continue {
    background-color: #1b5e20;
    float: right;
}

.back-drop {
    background-color: rgba(41, 41, 41, 0.788);
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    transition: all 0.6s;
    width: 100%;
    z-index: 99999;
}

.back-drop-closed {
    background-color: transparent;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    transition: all 0.6s;
    width: 100%;
    z-index: -99999;
}

.open-modal-btn {
    margin: 15px;
    padding: 10px;
    font-weight: bold;
}

